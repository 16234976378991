<template>

  <div class="serverList">

    <div class="newentry">
        <div class="serverCount">{{ getFilteredListCount }} Einträge gefunden</div>
        <div class="buttons">
        <button class="uk-button uk-button-default ok-btn" @click="toggleShow()">Neuen Eintrag hinzufügen</button>
        <button class="uk-button uk-button-default ok-btn" @click="exportPDF()">PDF herunterladen</button>
        </div>
   </div>
   <div id="pdf"></div>
    <div class="edit" :class="{'active':showEditing}"  >
      <div class="night" @click="toggleShow()"></div>

      <ServerListEdit

      isnew
      :itemid="values.itemid" 
      :servername="values.servername" 
      :serveraddress="values.serveraddress"
      :ip="values.ip"
      
        :ping="values.ping"
       @save="saveItem"
       @cancel="toggleShow"

      ></ServerListEdit>

   </div>
    <div v-if="isLoading">
        Lädt...
    </div>
    <div v-else-if="!isLoading && (!servers || servers.length === 0)">
        Keine server-Daten gefunden.
    </div>
    <table id="serverlist" v-else-if="!isLoading && servers && servers.length > 0">
        <ServerListItem 
            v-for="server in filteredList"
            :msg="server.servername"
            :key="server.id"
            :servername="server.servername"
            :serveraddress="server.serveraddress"
           
            :values="server"
            :tmpvalues="server"
            :ping="server.ping"
            @itemdeleted="loadserverData"
        />
    </table>


   </div>
</template>

<script>

import axios from 'axios';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import html2canvas from 'html2canvas'

import ServerListItem from '@/components/ServerListItem.vue'
import ServerListEdit from '@/components/ServerListEdit.vue'

export default {
    name: 'ServerList',
    components: {
        ServerListItem,
        ServerListEdit
    },
    props: {
        servernameFilter: String,
        serveraddressFilter: String,
        
        
    },
    data() {
        return {
        servers: [],
        isLoading: false,
        showEditing: false,

        values: {
            itemid: '',
            servername: '',
            serveraddress: '',
            
            ip: '',
           
            ping: false,
        },

        searchServername: '',
        searchServeraddress: '',
        
        }
    },
  
    methods: {
        /*
        fetchData() {
        this.axios.get('https://www.vogel.media/serverlist').then(response => (responseData = response));
        },*/


/* 
        savePDF(){
      
      var pdf = new jsPDF('p','pt','a4');
      
      pdf.addHTML(document.querySelector('.serverList'),function() {

      });
      pdf.save('pdfTable.pdf');
    }, */




        exportPDF() {

            var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "portrait" });


            // It can parse html:
            // <table id="my-table"><!-- ... --></table>
            //doc.autoTable({ html: '#serverlist' })

            // Or use javascript directly:
           
            let tmpbody = [];
            const tmpFilteredList = this.filteredList;
           
             
             for(let i=0; i < tmpFilteredList.length; i+=1) {
                tmpbody[i] = [
                    tmpFilteredList[i].id, 
                    tmpFilteredList[i].servername, 
                    tmpFilteredList[i].serveraddress,
                    tmpFilteredList[i].ip, 
                    tmpFilteredList[i].ping
                ];

            }


            var image = new Image();
            image.src = 'logo-vm.png';
            //image.src = "//i.imgur.com/QJ4AJXKb.jpg";  // some random imgur image
            image.crossOrigin = "";  // for demo as we are at different origin than image

            doc.setFontSize(16);
            doc.setTextColor('ff8c00')
            doc.text('Server-Liste',210-15,15,{align: 'right'});


            doc.autoTable({
            startY: 30,
             headStyles: { fillColor: [245, 135, 0] },
            head: [['ID', 'Servername', 'Serveradresse', 'IP', 'Pingstatus']],
            body: tmpbody,
            })

            image.onload = function() {
                doc.addImage(this, 15, 15, 50, 9);
                doc.save("test.pdf");
            };

            //doc.save("a4.pdf");
        },
        

        toggleShow(){
            this.showEditing = !this.showEditing
        },

        loadserverData() {

            const formData = {
                
                app: "server",
                function: "getServerList",
            }

            this.isLoading = true;

/*             
            fetch('https://www.vogel.media/server.php').then((response) => {
                if(response.ok) {
                return response.json();
            }
            }).then((data) => {
                const results = [];
                console.log(data);
                this.servers= data;
                this.isLoading = false;  
                                    
            })
 */

            axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
            .then(res => {
                const objekt = res;
                
                

/*                 for(row in objekt) {
                    row.ping = this.stringToBoolean(row.ping);
                }
 */                
                return objekt;
            }).then((data) => {

                this.servers= data.data;
                for (var i = 0; i < this.servers.length; i++) {
                    this.servers[i].ping = this.stringToBoolean(this.servers[i].ping);
                }
                //console.log(this.servers);
                this.isLoading = false;  

            })

        




        },
    

        saveItem: function(
                tmpitemid,
                tmpservername, 
                tmpserveraddress,
                tmpip,
                
                tmpping

        ) {
            
            const formData = {
            app: "server",
            function: "newServerEntry",
            itemid: tmpitemid,
            servername: tmpservername,
            serveraddress: tmpserveraddress,
            
            ip: tmpip,
            
            ping: tmpping,
            }
            console.log(formData.ping);
            if(formData.ping === true) {
            formData.ping = "1";
            }else {
            formData.ping = "0";
            }
            axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
            .then(res => {
                this.toggleShow();
                this.loadserverData();
            })
            
            
            
            
            
            

        },


       stringToBoolean: function(string) {
         switch(string) {
           case "true": case "yes": case "1": return true;
           case "false": case "no": case "0": return false;
         }
       },
      



    },
    computed: {
        filteredList() {
            
            this.searchServername = this.servernameFilter;
            this.searchServeraddress = this.serveraddressFilter;
            
            return this.servers.filter(server => {
                return server.servername.toLowerCase().includes(this.searchServername.toLowerCase()) 
                && server.serveraddress.toLowerCase().includes(this.searchServeraddress.toLowerCase()) 
                
            })



        },
        getFilteredListCount() {
            return this.filteredList.length;
        }

    },
    mounted() {
        this.loadserverData();
    }
}
</script>

