<template>
  <div class="home">
    <h1>Liste unserer Server</h1>
    <div class="uk-container">
   
   
    <ServerList
      :servername-filter="searchFilterServername"
      :serveraddress-filter="searchFilterServeraddress"
      
    ></ServerList>
   
  </div>


   </div>
</template>

<script>
// @ is an alias to /src
import ServerList from '@/components/ServerList.vue'

export default {
  name: 'Server',
  components: {
    ServerList,
  },
  data() {
    return {
       searchFilterServername: '',
      searchFilterServeraddress: '',

     }
  },
  
  
  methods: {
/* 
    filterList(domainname, hostingserver, cms, emailsystem) {
      this.searchFilterDomainname = domainname;
      this.searchFilterHostingserver = hostingserver;
      this.searchFilterCms = cms;
      this.searchFilterEmailsystem = emailsystem;
    },

 */
  },
  
}
</script>
