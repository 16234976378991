
<template>
  <div class="website">
    <div class="listitem">
      <div class="listcontent">
          <div class="id" >{{ values.id }}</div>
          <div class="servername">{{ values.servername }}</div>
          <div class="serveraddress"><a :href="serveraddresse"  target=_blank rel=noopener>{{ values.serveraddress }}</a></div>
          
          <div class="ip" v-clipboard="values.ip"><a>{{ values.ip }}</a></div>
          <div class="pingstatus " :class="[pingstatus]"><i class="fas fa-circle"></i>{{ stringToBoolean(values.ping) }}</div>

      </div>
      <div class="listactions">

        <a class="uk-button " @click="toggleShow()" ><i class="far fa-edit"></i></a>
        <a class="uk-button " @click="toggleDeleteShow()" ><i class="fas fa-trash-alt"></i></a>
      </div>

    </div>
    <div class="edit" :class="{'active':showEditing}"  >
      <div class="night" @click="toggleShow()"></div>

      <ServerListEdit
  
      
       :itemid="values.id"
       :servername="values.servername" 
      :serveraddress="values.serveraddress"
      :ip="values.ip"
      
       @save="saveItem"
       @cancel="toggleShow"

      ></ServerListEdit>


    </div>
    <div class="delete" :class="{'active':showDelete}"  >
      <div class="night" @click="toggleDeleteShow()"></div>

      <ServerListItemDelete
  
       :itemid="values.id"
       :servername="values.servername" 
      

       @delete="deleteItem"
       @cancel="toggleDeleteShow"

      ></ServerListItemDelete>


    </div>
  </div>
</template>
 <!--

<template>
    <tr class="listitem">
          <td class="id" >{{ values.id }}</td>
          <td class="servername">{{ values.servername }}</td>
          <td class="serveraddress"><a :href="serveraddresse"  target=_blank rel=noopener>{{ values.serveraddress }}</a></td>
          
          <td class="ip" v-clipboard="values.ip"><a>{{ values.ip }}</a></td>
          <td class="pingstatus " :class="[pingstatus]"><i class="fas fa-circle"></i>{{ stringToBoolean(values.ping) }}</td>

      <td class="listactions">

        <a class="uk-button " @click="toggleShow()" ><i class="far fa-edit"></i></a>
        <a class="uk-button " @click="toggleDeleteShow()" ><i class="fas fa-trash-alt"></i></a>
      </td>

    </tr>
    <div class="edit" :class="{'active':showEditing}"  >
      <div class="night" @click="toggleShow()"></div>

      <ServerListEdit
  
      
       :itemid="values.id"
       :servername="values.servername" 
      :serveraddress="values.serveraddress"
      :ip="values.ip"
      
       @save="saveItem"
       @cancel="toggleShow"

      ></ServerListEdit>


    </div>
    <div class="delete" :class="{'active':showDelete}"  >
      <div class="night" @click="toggleDeleteShow()"></div>

      <ServerListItemDelete
  
       :itemid="values.id"
       :servername="values.servername" 
      

       @delete="deleteItem"
       @cancel="toggleDeleteShow"

      ></ServerListItemDelete>


    </div>
</template>
 -->
<script>

import axios from 'axios';
import ServerListEdit from '@/components/ServerListEdit.vue'
import ServerListItemDelete from '@/components/ServerListItemDelete.vue'

export default {
  name: 'ServerListItem', 
  components: {
    ServerListEdit,
    ServerListItemDelete
  },
  props: {
    servername: String,
    serveraddress: String,
    
    values: Object,
    



    
  },
  emits: ['itemdeleted'],

  data() {
    return {

      showEditing: false,
      showDelete: false,
      tmpvalues: Object,
      //_tmpvals: Object,

      pingstatus: 'grey',
      
    }
  },
  
  methods: {
      toggleEditing: function(event) {
        event.preventDefault();
        this.showEditing = !this.showEditing;
        
        
      },
      toggleShow(){
    	  this.showEditing = !this.showEditing
      },
      toggleDeleteShow(){
    	  this.showDelete = !this.showDelete
      },
      getPingStatus(){
        if(this.values.ping === false) {
          return 'deaktiviert';
        }else{
          if( this.values.lastping > 0){
            if((this.values.lastbadping) && (this.values.lastbadping >=  this.values.lastping)) {
              return 'red';
              
            }else{
              return 'green';
            }

          }else{
            return 'grey';
          }
        }
      },

      saveItem: function(
            tmpitemid, 
            tmpservername, 
            tmpserveraddress,
            
            tmpip,
            
            tmpping,

      ) {
        
        const formData = {
          id: tmpitemid,
          app: "server",
          function: "updateServerEntry",
          servername: tmpservername,
          serveraddress: tmpserveraddress,
          
          ip: tmpip,
          
          ping: tmpping,
        }
        console.log(formData.ping);
        if(formData.ping === true) {
          formData.ping = "1";
        }else {
          formData.ping = "0";
        }
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then(
           res => {
              this.getServerEntry()
           }
          
        
        
        )

       },





      deleteItem: function(
         

      ) {
        
        const formData = {
          id: this.tmpvalues.id,
          app: "server",
          function: "deleteServerEntry",
          
        }
        //console.log(formData);
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then( result => {
            this.$emit('itemdeleted')
         }
        
        )

       },

      getServerEntry: function() {
       
        const formData = {
          id: this.tmpvalues.id,
          app: "server",
          function: "getServerEntry",
        }
        //console.log(formData);
        
        axios.post('https://www.vogel.media/server.php',JSON.stringify( formData))
        .then(res => {
            const objekt = res;

            this.values.servername = objekt.data[0]['servername'] ;
            this.values.serveraddress = objekt.data[0]['serveraddress'] ;
            
            this.values.ip = objekt.data[0]['ip'] ;
            
            this.values.ping = this.stringToBoolean( objekt.data[0]['ping']) ;
            this.pingstatus = this.getPingStatus();

             if(this.showEditing === true) {
              this.toggleShow();
            }
        
        })
       

       },
       stringToBoolean: function(string) {
         switch(string) {
           case "true": case "yes": case "1": return true;
           case "false": case "no": case "0": return false;
         }
       },
   
  },
  computed: {
      serveraddresse: function() {
        return `https://${this.serveraddress}/easytecc`;
      },


        
      
  },
  mounted() {
    
      this.tmpvalues = JSON.parse(JSON.stringify(this.values));;
      this.pingstatus = this.getPingStatus();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

