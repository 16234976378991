<template>
    
      <div class="editForm">
          <div class="content">
            <h2>Servereintrag bearbeiten</h2>
            <div class="frame">
                <div class="hidden">
                    <label for="id">id:</label>
                    <input readonly name="id" class="uk-input" v-model="tmpitemid" placeholder="id"> <br />    
                </div>
                <label for="servername">Servername:</label>
                <input name="servername" class="uk-input" v-model="tmpservername" placeholder="Servername"> <br />    

                <label for="serveraddress">Adresse (ohne https://):</label>
                <input name="serveraddress" class="uk-input" v-model="tmpserveraddress"  placeholder="Server-Adresse">   <br />           

                

                <label for="ip">IP:</label>
                <input name="ip" class="uk-input" v-model="tmpip"  placeholder="IP"> <br /> 

                


            </div>
          </div>
          <div class="frame left">
                <Switch v-model:checked="tmpping" label="Monitoring über Ping aktivieren" />

          </div>
          <div class="frame">
                <button class="uk-button uk-button-default ok-btn" @click="doSave">Speichern</button>
                <button class="uk-button uk-button-default" @click="doCancel">Schließen</button>
          </div>
     
      </div>

</template>

<script>
import ServerListEdit from '@/components/ServerListEdit.vue'
import Switch from '@/components/Switch.vue'

export default {

    name: 'ServerListEdit', 
    components: {
        ServerListEdit,
        Switch
    },


  props: {
    isnew: Boolean,

    itemid: String,
    servername: String,
    serveraddress: String,
    
    ip: String,
    
    ping: Boolean
     
    //initialvalue: Object,
  },


  
  data() {
    return {
        tmpisnew: true,

        tmpitemid: '',
        tmpservername: '',
        tmpserveraddress: '',
        
        tmpip: '',
       
        tmpping: false,
       // valueemit: Object.assign({}, this.initialvalue)
    }

    
  },

    methods: {
        doSave(event) {
            event.preventDefault();

            this.$emit('save', 
            this.tmpitemid, 
            this.tmpservername, 
            this.tmpserveraddress,
            
            this.tmpip,
            
            this.tmpping
            );
            
        },
        doCancel(event) {
            event.preventDefault();
            if (this.tmpisnew === true) {
 
            this.tmpitemid = '';
            this.tmpservername = '';
            this.tmpserveraddress = '';
            
            this.tmpip = '';
            
            this.tmpping = false;
             
            }
            this.$emit('cancel');
            
        },
        
    },
    mounted() {
        if(!this.isnew) {
        this.tmpitemid = this.itemid;
        this.tmpservername = this.servername;
        this.tmpserveraddress = this.serveraddress;
        this.tmpip = this.ip;
       
        this.tmpping = this.ping; 
        this.tmpisnew = this.isnew; 
        }
        

    }


}

</script>